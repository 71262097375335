import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <main className="main">
      <div className="para">
        <h2>A fullstack engineer, teacher and traveller!</h2>
        <p>
          “Fear is a pattern that we develop over years and that impacts
          everything we do in a negative way. It can lead to paralysis in
          choosing big decisions or avoiding situations that can help us grow.
          So face your fear, go through it and transform it into your strength.”
          ― Todd Perelmuter
        </p>
      </div>

      <h1 className="qoute">
        “Logic will get you from A to B. Imagination will take you everywhere.”
        –Albert Einstein
      </h1>

      <img className="home-image" src="./images/homeImage.jpg" alt="" />
    </main>
  );
};

export default Home;
